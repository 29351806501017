export interface TsAppVersion {
    version: string;
    name: string;
    description?: string;
    versionLong?: string;
    versionDate: string;
    gitCommitHash?: string;
    gitCommitDate?: string;
    gitTag?: string;
};
export const versions: TsAppVersion = {
    version: '35.0.0',
    name: 'lingo2-web',
    versionDate: '2024-10-08T19:01:07.340Z',
    gitCommitHash: 'c21ed15',
    versionLong: '35.0.0-c21ed15',
};
export default versions;
