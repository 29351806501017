import { ChangeDetectorRef, Directive, EventEmitter, Injector, Input, Output } from '@angular/core';
import { ILibrarySorting } from '@app/library/library-sorting/library-sorting.component';
import {
  AccountDetailsType,
  AccountService,
  AnalyticsService,
  CachedService,
  CacheTtlRule,
  ClassroomsService,
  CollectionsService,
  CommentService,
  ContentService,
  ContextService,
  DEFAULT_COLLECTIONS_DETAILS,
  defaultClassroomDetails,
  fullUserServiceDetails,
  IconsService,
  MeetingsService,
  PlatformService,
  SchoolsService,
  UserServicesService,
} from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import {
  AnyType,
  Classroom,
  Collection,
  Content,
  CSchool,
  EContentPaneFormat,
  EContentPanelRoute,
  FindAccountStrategyEnum,
  IContentFilterBlock,
  IContentPane,
  IFindAccountsFilter,
  IPagedResults,
  IPagination,
  Meeting,
  User,
  UserService,
} from 'lingo2-models';
import { chunk, keyBy, uniq } from 'lodash-es';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable, of, zip } from 'rxjs';
import { catchError, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ILibraryTitle } from '../../library-title/library-title.component';

let extendUsersPaginatedIndex = 1;

export interface IAbstractPaneServices {
  commentService: CommentService;
  contentService: ContentService;
  accountService: AccountService;
  meetingService: MeetingsService;
  userServicesService: UserServicesService;
  schoolsService: SchoolsService;
  classroomsService: ClassroomsService;
  collectionsService: CollectionsService;
}

export type ContentType = 'content' | 'collections' | 'classrooms' | 'userServices' | 'schools';

@Directive()
export abstract class AbstractPane extends ChangableComponent {
  public title: string | string[];
  public blockTitle: Partial<ILibraryTitle> = {};
  public sorting: Partial<ILibrarySorting> = {};
  public svgsetIcon = IconsService.svgsetIconUrl;
  public isDebug$: Observable<boolean>;

  protected addLink = '';
  protected titleLink = '';
  protected title_link = '';
  protected seeAllLink = '';
  protected _pane: IContentPane;
  protected accountDetails: AccountDetailsType[] = [
    'id',
    'country',
    'slug',
    'first_name',
    'last_name',
    'userpic_id',
    'profile',
    'segments',
    'stats',
    'created_at',
    'rating',
    'last_active_at',
    'visit_info',
  ];
  protected classroomDetails = defaultClassroomDetails;
  protected userServiceDetails = fullUserServiceDetails;
  protected _loadedItemsCount = 0;

  @Output() public loadFinished = new EventEmitter<boolean>();

  public readonly deviceService: DeviceDetectorService;
  protected readonly contextService: ContextService;
  protected readonly dataServices: IAbstractPaneServices;
  protected readonly cachedService: CachedService;
  protected analytics: AnalyticsService;

  public constructor(protected readonly inject: Injector) {
    super(inject.get(ChangeDetectorRef), inject.get(PlatformService));
    this.deviceService = inject.get(DeviceDetectorService);
    this.contextService = inject.get(ContextService);
    this.isDebug$ = this.contextService.debug$;
    this.dataServices = {
      commentService: inject.get(CommentService),
      contentService: inject.get(ContentService),
      accountService: inject.get(AccountService),
      meetingService: inject.get(MeetingsService),
      userServicesService: inject.get(UserServicesService),
      schoolsService: inject.get(SchoolsService),
      collectionsService: inject.get(CollectionsService),
      classroomsService: inject.get(ClassroomsService),
    };
    this.cachedService = inject.get(CachedService);
    this.analytics = inject.get(AnalyticsService);
  }

  @Input()
  public set pane(value: IContentPane) {
    this._pane = value;
    this.init();
  }

  public get pane(): IContentPane {
    return this._pane;
  }

  public get user(): User {
    return this._pane?.user;
  }

  public get users(): User[] {
    return this._pane?.users || [];
  }

  public get meetings(): Meeting[] {
    return this._pane?.meetings || [];
  }

  public get userServices(): UserService[] {
    return this._pane?.userServices || [];
  }

  public get filterPanels(): IContentFilterBlock[] {
    return this._pane?.filters || [];
  }

  public get schools(): Array<Partial<CSchool>> {
    return this._pane?.schools || [];
  }

  public get classrooms(): Classroom[] {
    return this._pane?.classrooms || [];
  }

  public get collections(): Array<Partial<Collection>> {
    return this._pane?.collections || [];
  }

  public get contents(): Array<Partial<Content>> {
    return this._pane?.content || [];
  }

  public get subtitle(): string {
    if (this._pane && this._pane.subtitle) {
      return this._pane.subtitle;
    }
    return '';
  }

  public get loaded(): boolean {
    return this._pane && this._pane.loaded;
  }

  public isVisible = false;

  /** @deprecated Use isVisible instead */
  public get loadedItemsCount() {
    return this._loadedItemsCount;
  }

  public hasContent(): boolean {
    if (!this._pane) {
      return false;
    }
    // if (AbstractPane.getStaticTypes().includes(this._pane.format as EContentPaneFormat)) {
    //   return true;
    // }
    if (
      this._pane.min_amount_to_show &&
      this._pane.min_amount_to_show > 0 &&
      this._pane.min_amount_to_show > this._loadedItemsCount
    ) {
      return false;
    }
    return (
      !!this.user ||
      this.users.length > 0 ||
      this.meetings.length > 0 ||
      this.contents.length > 0 ||
      this.filterPanels.length > 0 ||
      this.userServices.length > 0 ||
      this.schools.length > 0 ||
      this.classrooms.length > 0 ||
      this.collections.length > 0
    );
  }

  public load() {
    if (!this._pane) {
      return;
    }
    // Do nothing if already under loading
    if (this._pane.loading) {
      return;
    }
    if (this._pane.loaded) {
      return this.loadDataFinished(false);
    }
    // Meetings flow
    if (this._pane.meetingFilter) {
      return this.loadMeetings();
    }
    // Users flow
    if (this._pane.userFilter) {
      if (this._pane.replace_content_type) {
        return this.loadDataWithReplace(this._pane.userFilter);
      } else {
        return this.loadUsers();
      }
    }
    // User flow
    if (this._pane.userId) {
      return this.loadSingleUser();
    }
    // User services
    if (this._pane.userServiceFilter) {
      if (this._pane.replace_content_type) {
        return this.loadDataWithReplace(this._pane.userServiceFilter);
      } else {
        return this.loadUserServices();
      }
    }
    // Subjects & categories
    if (this._pane.directoriesFilter) {
      this.loadFilters();
      return;
    }
    // Meetings reviews
    if (this._pane.meetingReviewsFiler) {
      this.loadMeetingsReviews();
      return;
    }
    // Schools
    if (this._pane.schoolsFilter) {
      if (this._pane.replace_content_type) {
        return this.loadDataWithReplace(this._pane.schoolsFilter);
      } else {
        return this.loadSchools();
      }
    }
    // Classrooms
    if (this._pane.classroomsFilter) {
      return this.loadClassrooms();
    }

    // Collections
    if (this._pane.collectionsFilter) {
      if (this._pane.replace_content_type) {
        return this.loadDataWithReplace(this._pane.collectionsFilter);
      } else {
        return this.loadCollection();
      }
    }

    // Lessons
    if (this._pane.contentFilter) {
      this.loadContents();
      return;
    }

    // Any other flow
    this.loadDataFinished(false);
  }

  protected init() {
    this.title = this.calculateTitle();
    if (this._pane) {
      this.addLink = ![undefined].includes(this._pane?.routerUrlCreate) ? this._pane?.routerUrlCreate : '';
      // LibraryRouter.getCreateLinkByPane(this._pane);
      this.seeAllLink = ![undefined, ''].includes(this._pane?.routerUrlAll) ? this._pane?.routerUrlAll : '';
      // LibraryRouter.getSeeAllLinkByPane(this._pane);
      this.titleLink = this.seeAllLink; // TODO ? отдельная ссылка
      this.title_link = this._pane?.title_link ? this._pane?.title_link : '';
    } else {
      this.addLink = '';
      this.seeAllLink = '';
      this.titleLink = '';
    }

    this.blockTitle = {
      ...this.blockTitle,
      title: this.title,
      subtitle: this.subtitle, // this.subtitle === undefined ? 'library.titles.media' :
      add_link: this.addLink,
      see_all_link: this._pane.infinity ? '' : this.seeAllLink,
      title_link: this.titleLink,
      new_title_link: this.title_link,
    };
  }

  protected loadNextPage() {
    if (!this._pane.loaded || !this._pane.pagination) {
      return false;
    }
    if (!this._pane.infinity) {
      return false;
    }
    if (this._pane.pagination.page >= this._pane.pagination.totalPages) {
      return false;
    }
    this._pane.loaded = false;
    this._pane.pagination.page += 1;
    this.load();
  }

  protected calculateTitle(): string | string[] {
    return this._pane?.title || '';
  }

  protected loadFilters() {
    if (!this.dataServices.contentService) {
      this.handleNoServiceError('contentService');
      return;
    }

    let section: 'subjects' | 'categories';

    switch (this._pane.format) {
      case EContentPaneFormat.subjects:
        section = 'subjects';
        break;
      case EContentPaneFormat.categories:
        section = 'categories';
        break;
      default:
        return;
    }

    this._pane.loading = true;
    this.dataServices.contentService
      .getContentFilterBlocks(section, this._pane.directoriesFilter)
      .pipe(catchError((err: any): [IContentFilterBlock[]] => [[]]))
      .pipe(takeUntil(this.destroyed$))
      .subscribe((sections) => {
        this._pane.filters = sections.filter((el) => el.code !== 'vocabulary').filter((el) => el.code !== 'news');
        this._loadedItemsCount = this._pane.filters.length;
        this.loadDataFinished(true);
      });
  }

  protected loadMeetings() {
    if (!this.dataServices.meetingService) {
      this.handleNoServiceError('Meeting service');
      return;
    }

    if (!this._pane.loaded) {
      this._pane.loading = true;
      this.detectChanges();

      const pagination: IPagination = this._pane.pagination
        ? this._pane.pagination
        : {
            page: 1,
            pageSize: 10,
            total: 0,
            totalPages: 0,
          };
      this.dataServices.meetingService
        .getMeetings(this._pane.meetingFilter, pagination)
        .pipe(
          switchMap((response) => this.extendUsersPaginated$(response, 'author_id', 'author', 'FOREVER')),
          takeUntil(this.destroyed$),
        )
        .subscribe(
          (meetings) => {
            this._pane.pagination = meetings.pagination;
            this._pane.meetings = meetings.results;
            this._loadedItemsCount = this._pane.meetings.length;
            this.loadDataFinished(true);
          },
          (err: any) => {
            this._pane.pagination = pagination;
            this._pane.meetings = [];
            this._loadedItemsCount = this._pane.meetings.length;
            this.loadDataFinished(true);
          },
        );
    } else {
      this.loadDataFinished(false);
    }
  }

  protected loadUsers(filter?: any) {
    if (!this._pane.loaded) {
      if (!this.dataServices.accountService) {
        this.handleNoServiceError('Account service');
        return;
      }

      this._pane.loading = true;
      const pagination: IPagination = this._pane.pagination
        ? this._pane.pagination
        : {
            page: 1,
            pageSize: +this._pane.limit || 10,
            total: 0,
            totalPages: 0,
          };

      const replaceFilter = filter ? filter : this._pane.userFilter;

      this.dataServices.accountService
        .findAccounts(replaceFilter, pagination, this.accountDetails)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          (users) => {
            this._pane.pagination = users.pagination;
            this._pane.users = this._pane.users ? this._pane.users.concat(users.results) : users.results;
            this._loadedItemsCount = this._pane.users.length;
            // Если не установлен userId, то записать в user какого-нибудь рандомчика
            if (!this._pane.userId && users.results.length > 0) {
              this._pane.user = users.results[Math.floor(Math.random() * users.results.length)];
            }
            this.loadDataFinished(true);
          },
          (err: any) => {
            this._pane.pagination = pagination;
            this._pane.users = [];
            this._loadedItemsCount = this._pane.users.length;
            this.loadDataFinished(true);
          },
        );
    } else {
      this.loadDataFinished(false);
    }
  }

  protected loadSingleUser() {
    if (!this._pane.user && !this._pane.loaded) {
      if (!this.dataServices.accountService) {
        this.handleNoServiceError('accountService service');
        return;
      }

      this._pane.loading = true;
      // details ???
      this.dataServices.accountService
        .getUserById(this._pane.userId, 'FOREVER')
        .pipe(first(), takeUntil(this.destroyed$))
        .subscribe({
          next: (user) => {
            if (user) {
              this._pane.user = user;
              this._loadedItemsCount = user ? 1 : 0;
            }
            this.loadDataFinished(true);
          },
          error: (error) => {
            this._pane.user = null;
            this._loadedItemsCount = 0;
            this.loadDataFinished(true);
          },
        });
    } else {
      this.loadDataFinished(false);
    }
  }

  protected loadUserServices(filter?: any) {
    if (!this._pane.loaded) {
      if (!this.dataServices.userServicesService) {
        this.handleNoServiceError('userServicesService service');
        return;
      }
      this._pane.loading = true;
      this._pane.infinity = true;
      const pagination: IPagination = this._pane.pagination
        ? this._pane.pagination
        : {
            page: 1,
            pageSize: 10,
            total: 0,
            totalPages: 0,
          };

      const replaceFilter = filter ? filter : this._pane.userServiceFilter;

      this.dataServices.userServicesService
        .getServices(replaceFilter, pagination, this.userServiceDetails)
        .pipe(
          switchMap((response) => this.extendUsersPaginated$(response, 'author_id', 'author', 'FOREVER')),
          takeUntil(this.destroyed$),
        )
        .subscribe({
          next: (results) => this.paginatedContentLoader(results, 'userServices'),
          error: (error) => {
            this._pane.pagination = pagination;
            this._pane.userServices = [];
            this._loadedItemsCount = this._pane.userServices.length;
            this.loadDataFinished(true);
          },
        });
    } else {
      this.loadDataFinished(false);
    }
  }

  protected loadMeetingsReviews() {}

  protected loadSchools(filter?: any) {
    if (!this._pane.loaded) {
      if (!this.dataServices.schoolsService) {
        this.handleNoServiceError('schoolsService service');
        return;
      }
      this._pane.loading = true;
      const pagination: IPagination = this._pane.pagination
        ? this._pane.pagination
        : {
            page: 1,
            pageSize: 10,
            total: 0,
            totalPages: 0,
          };
      delete this._pane.schoolsFilter?.language_id;

      const replaceFilter = filter ? filter : this._pane.schoolsFilter;

      this.dataServices.schoolsService
        .findSchools(replaceFilter, pagination)
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: (results) => this.paginatedContentLoader(results, 'schools'),
          error: (error) => {
            this._pane.pagination = pagination;
            this._pane.schools = [];
            this._loadedItemsCount = this._pane.schools.length;
            this.loadDataFinished(true);
          },
        });
    } else {
      this.loadDataFinished(false);
    }
  }

  protected loadClassrooms() {
    if (!this._pane.loaded) {
      if (!this.dataServices.classroomsService) {
        this.handleNoServiceError('classroomsService service');
        return;
      }
      this._pane.loading = true;
      this._pane.infinity = true;
      const pagination: IPagination = this._pane.pagination
        ? this._pane.pagination
        : {
            page: 1,
            pageSize: 10,
            total: 0,
            totalPages: 0,
          };
      this.dataServices.classroomsService
        .getClassrooms(this._pane.classroomsFilter, pagination, this.classroomDetails)
        .pipe(
          switchMap((response) => this.extendUsersPaginated$(response, 'author_id', 'author', 'FOREVER')),
          takeUntil(this.destroyed$),
        )
        .subscribe({
          next: (results) => this.paginatedContentLoader(results, 'classrooms'),
          error: (error) => {
            this._pane.pagination = pagination;
            this._pane.classrooms = [];
            this._loadedItemsCount = this._pane.classrooms.length;
            this.loadDataFinished(true);
          },
        });
    } else {
      this.loadDataFinished(false);
    }
  }

  private loadCollection(filter?: any) {
    if (!this._pane.loaded) {
      if (!this.dataServices.collectionsService) {
        this.handleNoServiceError('collectionsService in Abstract Collection Pane');
        return;
      }
      this._pane.loading = true;
      const pagination: IPagination = this._pane.pagination
        ? this._pane.pagination
        : {
            page: 1,
            pageSize: 12,
            total: 0,
            totalPages: 0,
          };

      const replaceFilter = filter ? filter : this._pane.collectionsFilter;

      this.dataServices.collectionsService
        .findCollections(replaceFilter, pagination, DEFAULT_COLLECTIONS_DETAILS)
        .pipe(
          switchMap((response) => this.extendUsersPaginated$(response, 'author_id', 'author', 'FOREVER')),
          takeUntil(this.destroyed$),
        )
        .subscribe({
          next: (results) => this.paginatedContentLoader(results, 'collections'),
          error: (error) => {
            this._pane.pagination = pagination;
            this._pane.collections = [];
            this._loadedItemsCount = this._pane.collections.length;
            this.loadDataFinished(true);
          },
        });
    } else {
      this.loadDataFinished(false);
    }
  }

  public loadContents(filter?: any) {
    if (!this._pane.loaded) {
      if (!this.dataServices.contentService) {
        this.handleNoServiceError('contentService in Abstract Content Pane');
        return;
      }
      this._pane.loading = true;
      // this._pane.contentFilter.is_library = true;
      const pagination: IPagination = this._pane.pagination
        ? this._pane.pagination
        : {
            page: 1,
            pageSize: 12,
            total: 0,
            totalPages: 0,
          };
      if (this._pane.contentFilter && this._pane.contentFilter.related_to) {
        this.dataServices.contentService
          .getRelated(this._pane.contentFilter.related_to, this._pane.contentFilter, pagination)
          .pipe(
            switchMap((response) => this.extendUsersPaginated$(response, 'author_id', 'author', 'FOREVER')),
            takeUntil(this.destroyed$),
          )
          .subscribe({
            next: (contentResult) => this.paginatedContentLoader(contentResult, 'content'),
            error: (error) => {
              this._pane.pagination = pagination;
              this._pane.content = [];
              this._loadedItemsCount = this._pane.content.length;
              this.loadDataFinished(true);
            },
      });
      } else {
        const replaceFilter = filter ? filter : this._pane.contentFilter;
        this.dataServices.contentService
          .getContent(replaceFilter, pagination)
          .pipe(
            switchMap((response) => this.extendUsersPaginated$(response, 'author_id', 'author', 'FOREVER')),
            takeUntil(this.destroyed$),
          )
          .subscribe({
            next: (contentResult) => this.paginatedContentLoader(contentResult, 'content'),
            error: (error) => {
              this._pane.pagination = pagination;
              this._pane.content = [];
              this._loadedItemsCount = this._pane.content.length;
              this.loadDataFinished(true);
            },
      });
      }
    } else {
      this.loadDataFinished(false);
    }
  }

  protected handleNoServiceError(serviceName: string) {
    this.loadDataFinished(false);
  }

  protected paginatedContentLoader<T>(results: IPagedResults<T[]>, contentType: ContentType) {
    if (results.pagination.page === 1) {
      this._pane[contentType] = [];
    }
    this._pane.pagination = results.pagination;
    (this._pane[contentType] as any) = this._pane[contentType]
      ? [...this._pane[contentType], ...results.results]
      : results.results;
    this._loadedItemsCount = this._pane[contentType].length;
    this.loadDataFinished(true);
  }

  protected loadDataFinished(viaQuery: boolean) {
    this._pane.loaded = true;
    this._pane.loading = false;
    this.onContentLoaded(viaQuery);
    /**
     * Пояснение: строчку под этим комментарием
     * нужно будет убрать, когда переход
     * на isVisible будет сделан везде.
     *
     * isVisible должен быть установлен _каждым отдельным наследником_
     * внутри его соответствующего onContentLoaded.
     */
    this.isVisible = this.isVisible || this.loadedItemsCount > 0;
    this.loadFinished.emit(this.hasContent());
    this.detectChanges();
  }

  public loadDataWithReplace(filter: any) {
    if (this._pane.replace_content_type_type === EContentPanelRoute.lessons) {
      return this.loadContents(filter);
    } else if (this._pane.replace_content_type_type === EContentPanelRoute.classes) {
      return this.loadUserServices(filter);
    } else if (this._pane.replace_content_type_type === EContentPanelRoute.schools) {
      return this.loadSchools(filter);
    } else if (this._pane.replace_content_type_type === EContentPanelRoute.collections) {
      return this.loadCollection(filter);
    } else if (this._pane.replace_content_type_type === EContentPanelRoute.tutors) {
      return this.loadUsers(filter);
    }
  }

  protected abstract onContentLoaded(viaQuery?: boolean): void;

  /**
   * Дополняет списки записей данными о пользователях
   */
  protected extendUsersPaginated$<T = AnyType>(
    pagedValues: IPagedResults<T[]>,
    keyUserId = 'user_id',
    keyUser = 'user',
    ttlSec: CacheTtlRule,
  ): Observable<IPagedResults<T[]>> {
    const label = extendUsersPaginatedIndex++;
    // 1 найти id загружаемых данных их объекта pagedValues (ids)
    // 2 поискать хотя бы часть данных в кэше (cachedUsersIdx)
    // 3 исключить те id которые найдены в кжше (ids -> ids)
    // 4 загрузить данные порциями (ids -> idsChunks[] -> observables$[])
    // 5 соединить результаты в один массив (observables$[] -> mergeChunks$)
    // 6 соединить результаты в один массив (users, usersCached)
    // 7 дополнить данные в объекте pagedValues загруженными данными
    // 8 вернуть pagedValues

    let ids = this.pluckArray(pagedValues.results, keyUserId, keyUser);
    if (!ids.length) {
      return of(pagedValues);
    }

    const cachedUsersIdx: { [key: string]: User } = {};
    ids.map((id) => {
      const _user = this.cachedService.get<User>(['Account', id, undefined]);
      if (typeof _user !== 'undefined') {
        cachedUsersIdx[id] = _user;
      }
    });
    ids = ids.filter((id) => !(id in cachedUsersIdx));
    const usersCached = Object.values(cachedUsersIdx);

    const idsChunks = chunk(ids, 50);
    const observables$: Array<Observable<User[]>> = idsChunks.map((_ids) => {
      const _filter: Partial<IFindAccountsFilter> = { id: _ids, use: FindAccountStrategyEnum.id, label } as any;
      const pagination: IPagination = { page: 1, pageSize: _ids.length };
      return this.dataServices.accountService
        .findAccounts(_filter, pagination)
        .pipe(map((response: IPagedResults<User[]>) => response.results));
    });
    return this.mergeChunks$<User>(observables$).pipe(
      tap((users) => {
        // сохранение в кэш
        if (ttlSec) {
          users.map((_user) => {
            this.cachedService.set(['Account', _user.id, undefined], _user, ttlSec);
          });
        }
      }),
      map((users) => {
        // объединение полученных и кэшированных
        const _usersIdx = keyBy([...users, ...usersCached], 'id');
        const results = (pagedValues.results || []).map((item) => {
          if (item[keyUserId]) {
            item[keyUser] = _usersIdx[item[keyUserId]];
          }
          return item;
        });
        return {
          ...pagedValues,
          results,
        };
      }),
    );
  }

  protected mergeChunks$<T>(chunks$: Array<Observable<T[]>>): Observable<T[]> {
    return chunks$.length
      ? zip(...chunks$).pipe(map((chunks: T[][]) => chunks.reduce((arr, _chunk) => [...arr, ..._chunk], [] as T[])))
      : of([]);
  }

  /**
   * Возвращает для массива items массив значений из свойства keyId для тех записей,
   * у которых не заполнено свойство keyEntity
   */
  protected pluckArray(items: AnyType[], keyId: string, keyEntity: string): string[] {
    return uniq(
      items
        .filter((item) => !item[keyEntity])
        .map((item) => item[keyId])
        .filter((v) => !!v),
    );
  }
}
